<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="fixed z-10 inset-0 overflow-y-auto change-password-vue">
    <ValidationObserver v-slot="{ handleSubmit, reset }">
      <form @submit.prevent="handleSubmit(save)" @reset.prevent="reset">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-black opacity-50"></div>
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;

          <div
            aria-labelledby="modal-headline"
            aria-modal="true"
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full"
            role="dialog"
          >
            <div class="bg-white">
              <div class="sm:flex sm:items-start">
                <div class="w-full text-center sm:text-left">
                  <div class="bg-gray-50 py-6 px-8">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      {{
                        $t("change-password-0b39c5aca15b84b1ad53a94d6b3feb78")
                      }}
                    </h3>
                    <div
                      class="h-7 flex items-center absolute top-5 right-5"
                      @click="$emit('close')"
                    >
                      <button
                        aria-label="Close panel"
                        class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                        type="reset"
                      >
                        <!-- Heroicon name: x -->
                        <svg
                          class="h-5 w-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 18L18 6M6 6l12 12"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div>
                      <dl>
                        <div
                          v-if="data && !data.should_set_initial_password"
                          class="py-4 px-8 sm:grid sm:grid-cols-3 sm:gap-4"
                        >
                          <dt
                            class="text-sm leading-5 font-normal my-auto text-left"
                          >
                            {{ $t("old-password") }}
                          </dt>
                          <dd
                            class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="
                                $t(
                                  'password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number'
                                )
                              "
                              :rules="{ required: true }"
                            >
                              <div class="rounded-md shadow-sm">
                                <input
                                  id="password_current"
                                  v-model="password_current"
                                  :placeholder="$t('Password')"
                                  class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  type="password"
                                />
                              </div>
                              <span class="text-sm text-red-600 h-4">{{
                                errors[0]
                              }}</span>
                              <span
                                v-if="error.status"
                                class="text-sm text-red-600 h-4"
                                >{{ error.message }}</span
                              >
                            </ValidationProvider>
                          </dd>
                        </div>
                        <div class="px-8 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt
                            class="text-sm leading-5 font-normal my-auto text-left"
                          >
                            {{ $t("new-password") }}
                          </dt>
                          <dd
                            class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              :rules="{
                                required: true,
                                regex: passwordPattern,
                              }"
                              name="confirm"
                            >
                              <div class="rounded-md shadow-sm">
                                <input
                                  id="password_new"
                                  v-model="password_new"
                                  :placeholder="$t('Password')"
                                  class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  type="password"
                                />
                              </div>

                              <span
                                v-if="
                                  errors[0] === 'confirm' &&
                                  (whitelabel === 'ICMVC' ||
                                    whitelabel === 'ICMAP' ||
                                    whitelabel === 'ICMMU' ||
                                    whitelabel === 'ICMCapital' ||
                                    whitelabel === 'OTM' ||
                                    whitelabel === 'ICMMENA' ||
                                    whitelabel === 'VCGMarkets' ||
                                    whitelabel === 'ICMTrader' ||
                                    whitelabel === 'GCCBrokers')
                                "
                                class="text-sm text-red-600 h-4"
                              >
                                {{
                                  $t(
                                    "password-must-be-8-to-15-characters-long-contain-four-character-types-lowercase-letters-uppercase-letters-numbers-and-symbols"
                                  )
                                }}
                              </span>
                              <span
                                v-else-if="errors[0] === 'confirm'"
                                class="text-sm text-red-600 h-4"
                              >
                                {{
                                  $t(
                                    "password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number"
                                  )
                                }}
                              </span>
                              <span v-else class="text-sm text-red-600 h-4">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </dd>
                        </div>
                        <div class="px-8 py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt
                            class="text-sm leading-5 font-normal my-auto text-left"
                          >
                            {{ $t("confirm-new-password") }}
                          </dt>
                          <dd
                            class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              rules="password:@confirm|required"
                            >
                              <div class="rounded-md shadow-sm">
                                <input
                                  id="password_confirm"
                                  v-model="password_confirm"
                                  :placeholder="$t('Password')"
                                  class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  type="password"
                                />
                              </div>
                              <span
                                v-if="errors[0] === 'Passwords do not match'"
                                class="text-sm text-red-600 h-4"
                              >
                                {{
                                  $t(
                                    "your-passwords-do-not-match-please-check-and-try-again"
                                  )
                                }}</span
                              >
                              <span v-else class="text-sm text-red-600 h-4">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="py-6 px-8 sm:px-6 flex justify-between sm:justify-end border-t"
            >
              <span class="inline-flex rounded-md shadow-sm sm:mr-4">
                <Button
                  class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                  type="submit"
                >
                  {{ $t("save-changes") }}
                </Button>
              </span>
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                  type="reset"
                  @click="$emit('close')"
                >
                  {{ $t("Cancel") }}
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { passwordPattern } from "@/views/choices";

export default {
  name: "ChangePassword",
  data() {
    return {
      validation_message: this.$t(
        "password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number"
      ),
      password_current: "",
      password_new: "",
      password_confirm: "",
      error: {
        status: false,
        message: "",
      },
      passwordPattern: passwordPattern(),
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  props: {
    accountPassword: {
      type: Boolean,
      required: false,
      default: () => {
        return null;
      },
    },
    id: {
      type: Number,
      required: false,
      default: () => {
        return 0;
      },
    },
    data: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    save() {
      if (this.accountPassword) {
        let data = {};
        if (this.data.should_set_initial_password) {
          data = {
            password: this.password_new,
          };
        } else {
          data = {
            old_password: this.password_current,
            password: this.password_new,
          };
        }
        this.$store
          .dispatch("change_account_password", [data, this.id])
          .then((resp) => {
            this.$store.dispatch("account_data");
            this.$notify({
              group: "foo",
              text: `${this.$t(
                "trading-account-password-changed-successfully"
              )}`,
            });
            this.$emit("close");
          })
          .catch((err) => {
            this.error.status = true;
            // this.error.message = err.response.data.old_password[0]
            // this.error.message = err.data.non_field_errors[0]
            this.$notify({
              group: "foo",
              text: `${Object.entries(err.data)[0][1]}`,
              type: "warn",
            });
          });
      } else {
        const data = {
          password_current: this.password_current,
          password_new: this.password_new,
          password_confirm: this.password_confirm,
        };
        this.$store
          .dispatch("change_password", data)
          .then(() => {
            this.$notify({
              group: "foo",
              text: `${this.$t("password-successfully-changed")}`,
            });
            this.$emit("close");
          })
          .catch((err) => {
            const t = this.$t.bind(this);
            var errMessage = `${Object.entries(err.data)[0][1]}`;
            if (
              errMessage ===
              "Password must be 6 to 15 characters long, contain at least one uppercase and one lowercase letter, and one digit. Allowed special characters: -_!@#$%^&*"
            ) {
              errMessage = t(
                "password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number"
              );
            } else if (
              errMessage === "New password must differ from old password"
            ) {
              errMessage = t("New-password-must-differ-from-old-password");
            } else {
              errMessage = `${Object.entries(err.data)[0][1]}`;
            }
            this.$notify({
              group: "foo",
              text: errMessage,
              type: "warn",
            });
          });
      }
    },
  },
};
</script>
